<template>
  <div class="d-flex flex-column">
    <!-- #region DataGrid -->
    <template id="datagrid">
      <dx-data-grid
        ref="subscriptionDataGridRef"
        :data-source="dataSource"
        :show-borders="true"
        class="mt-2"
        :height="'calc(100vh - 160px)'"
        :column-auto-width="true"
        :show-column-lines="true"
        :show-row-lines="true"
        :hover-state-enabled="true"
        :row-alternation-enabled="false"
        @toolbar-preparing="onToolbarPreparing($event)"
      >
        <dx-sorting mode="single" />
        <dx-remote-operations
          :paging="true"
          :sorting="true"
          :filtering="false"
        />
        <template #filterToolbar>
          <div class="d-flex align-items-center">
            <dx-util-select-box
              v-model="selectedMembershipPlan"
              :items="membershipPlans"
              display-expr="text"
              value-expr="value"
              class="mr-half"
              width="200"
              @value-changed="onChangeMembershipPlan"
            />
            <!-- <dx-util-select-box
              v-model="selectedAudienceGroup"
              :items="audienceGroups"
              display-expr="text"
              value-expr="value"
              class="mr-half"
              width="200"
              @value-changed="onAudienceGroupChange"
            /> -->
          </div>
        </template>
        <dx-column data-field="membershipPlanName" caption="Plan Name" />
        <dx-column data-field="subscriptionStatus" caption="Status" cell-template="statusTemplate" />
        <template #statusTemplate="{ data }">
          <div :class="resolveStatusTemplate(data.value)">
            {{ data.value }}
          </div>
        </template>
        <dx-column v-if="!isIndividualTenant" data-field="companyAccountNo" caption="Account No" :width="100" />
        <dx-column data-field="companyName" />
        <dx-column data-field="tenantId" />
        <dx-column data-field="tenantType" />
        <dx-column data-field="memberAudienceType" />
        <dx-column data-field="storeCount" />
        <dx-column data-field="chargeSchedule" />
        <dx-column data-field="startTime" data-type="date" caption="Membership Start" />
        <dx-column data-field="#details" caption="Actions" cell-template="planDetailsTemplate" />
        <template #planDetailsTemplate="{data}">
          <div id="membership">
            <dx-util-button icon="icon bi-list" type="warning" class="mr-half" @click="showPlanDetails(data.data)" />
            <dx-util-drop-down-button
              :split-button="false"
              :use-select-mode="true"
              :drop-down-options="{ width: 210 }"
              :show-arrow-icon="true"
              icon="menu"
              display-expr="name"
              drop-down-content-template="dropDownTemplate"
              class="px-0"
            >
            <template #dropDownTemplate>
              <div class="d-flex flex-column p-half">
                <dx-util-button text="Cancel" icon="icon bi-x" type="danger" class="mx-0 w-100 text-left" style="margin-top: 2px;" @click="cancelSubscription(data.data)" />
                <dx-util-button v-if="data.data.tenantType !== 'INDIVIDUAL'" text="Change Plan" icon="icon bi-shuffle" styling-mode="text" class="mx-0 w-100 text-left" type="success" style="margin-top: 2px;" @click="changePlan(data.data)" />
                <dx-util-button v-if="data.data.chargeSchedule === 'MONTHLY'" icon="icon bi-journal-arrow-up" styling-mode="text" text="Change To Yearly" style="margin-top: 2px;" type="default" class="mx-0 w-100 text-left" @click="changeToYearly(data.data.membershipPlanId)" />
              </div>
            </template>
            </dx-util-drop-down-button>
          </div>
        </template>
        <dx-paging :page-size="pagerOptions.pageSize" />
        <dx-pager
          :allowed-page-sizes="pagerOptions.allowedPageSizes"
          :show-info="pagerOptions.showInfo"
          :show-navigation-buttons="pagerOptions.showNavigationButtons"
          :show-page-size-selector="pagerOptions.showPageSizeSelector"
          :visible="pagerOptions.visible"
        />
      </dx-data-grid>
    </template>
    <!-- #endregion -->
    <subscribed-plan-details :component-id="planDetailsComponentId" :plan-id="selectedPlanId" :plan-name="selectedPlanName" />
    <membership-plan-list :component-id="membershipPlanListComponentId" :tenant-type="selectedTenantType" />
  </div>
</template>

<script>
import GridBase from '@core/dev-extreme/mixins/grid/base'
import membershipPlanService from '@/http/requests/membership/plan'
import { v4 as uuidv4 } from 'uuid'
import useCurrentUser from '@/libs/app/current-user'
import { getAudienceTypeList } from '@/enums/membershipAudienceType.enum.js'
import membershipSubscriptionService from '@/http/requests/membership/subscription'
import { MembershipDataSource } from './membership-subscribers'

export default {
  name: 'MembershipSubscribers',
  components: {
    'subscribed-plan-details': () => import('./SubscribedPlanDetails.vue'),
    'membership-plan-list': () => import('@/views/apps/membership/components/MembershipPlanList.vue'),
  },
  directives: {
  },
  mixins: [GridBase],
  props: {
    qPlanId: {
      type: Number || null,
      default: null,
    },
  },
  setup() {
    const { userTenantType } = useCurrentUser()
    return { userTenantType }
  },
  data() {
    return {
      MembershipDataSource,
      selectedMembershipPlan: null,
      dataSource: [],
      selectEvent: null,
      membershipPlans: [],
      membershipPlansData: [],
      icons: ['Edit2Icon', 'TrashIcon'],
      isIndividualTenant: false,
      planDetailsComponentId: '',
      selectedPlanId: 0,
      selectedPlanName: '',
      selectedAudienceGroup: '',
      audienceGroups: getAudienceTypeList(),
      selectedTenantType: 'SERVING',
      membershipPlanListComponentId: '',
    }
  },
  computed: {
    isServingTenant() {
      return this.userTenantType === 'SERVING'
    },
    dataGrid() {
      return this.$refs.subscriptionDataGridRef.instance
    },
  },
  async mounted() {
    // this.loadMembershipTypes()
    await this.loadMembershipPlans()
  },
  methods: {
    async loadMembershipPlans() {
      const result = await membershipPlanService.getListByFilter()
      this.membershipPlansData = result.data.body
      result.data.body.forEach(element => {
        this.membershipPlans.push({ text: element.name, value: element.id, membershipTypeId: element.membershipTypeId })
      })
      this.membershipPlans.unshift({ text: 'All Membership Plans', value: 0 })
      this.selectedMembershipPlan = 0
      if (this.qPlanId) {
        this.selectedMembershipPlan = this.qPlanId
      }
    },
    onChangeMembershipType(e) {
      this.selectedMembershipType = e.value
    },
    onChangeMembershipPlan(e) {
      this.selectedMembershipPlan = e.value
      this.loadMembershipSubscriptions(e.value)
    },
    onAudienceGroupChange(e) {
      this.selectedAudienceGroup = e.value
    },
    onToolbarPreparing(e) {
      const toolbarItems = e.toolbarOptions.items
      toolbarItems.unshift({
        location: 'before',
        template: 'filterToolbar',
      })
      toolbarItems.push({
        widget: 'dxButton',
        options: {
          text: 'Plan Management',
          type: 'success',
          onClick: () => {
            this.$router.push({ name: 'membership-management' })
          },
        },
        location: 'after',
      })
      toolbarItems.push({
        widget: 'dxButton',
        options: {
          icon: 'refresh',
          onClick: () => {
            this.refreshTable()
          },
        },
        location: 'after',
      })
    },
    loadMembershipSubscriptions(id) {
      MembershipDataSource.searchValue({ membershipPlanId: id })
      MembershipDataSource.reload()
      this.dataSource = MembershipDataSource
    },
    refreshTable() {
      this.$refs.subscriptionDataGridRef.instance.state(null)
    },
    showPlanDetails(e) {
      this.planDetailsComponentId = uuidv4()
      this.selectedPlanId = e.membershipPlanId
      this.selectedPlanName = e.membershipPlanName
    },
    resolveStatusTemplate(status) {
      if (status === 'Active') return 'badge badge-success d-block'
      if (status === 'Cancelled') return 'badge badge-danger d-block'
      if (status === 'Closed') return 'badge badge-warning d-block'
      return 'badge badge-primary d-block'
    },
    changePlan(e) {
      // TODO:
      // Need to know subscription audience type or tenant type better.
      this.membershipPlanListComponentId = uuidv4()
      this.selectedTenantType = e.tenantType
    },
    // TODO: subscriptionId daha mantıklı olabilir. nokta atışı.
    changeToYearly(planId) {
      this.$swal({
        title: 'Are you sure you want to change this plan subscription to yearly?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, change it to yearly',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then((async result => {
        if (result.value) {
          const dataSource = this.dataGrid.getDataSource()
          const res = await membershipSubscriptionService.changeToYearly(planId)
          if (res) {
            this.$swal({
              icon: 'success',
              title: 'Enrolled!',
              text: 'You have enrolled yearly subscription.',
              customClass: {
                confirmButton: 'btn btn-success',
              },
            })
            dataSource.reload()
          }
        }
      }))
    },
    cancelSubscription(e) {
      this.$swal({
        title: 'Are you sure you want to cancel this plan subscription?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, delete subscription',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then((async result => {
        if (result.value) {
          const dataSource = this.dataGrid.getDataSource()
          const res = await membershipSubscriptionService.cancelCompanyPlan(e.subscriberCompanyId, e.membershipPlanId)
          if (res) {
            this.$swal({
              icon: 'success',
              title: 'Deleted!',
              text: 'Subscription has been cancelled.',
              customClass: {
                confirmButton: 'btn btn-success',
              },
            })
            dataSource.reload()
          }
        }
      }))
    },
  },
}
</script>

<style>
#membership .dx-dropdownbutton {
  background-color: #5BC2D5;
  border-radius: 4px;
}
</style>
