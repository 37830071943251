/* eslint-disable no-unused-vars */
import DataSource from 'devextreme/data/data_source'
import CustomStore from 'devextreme/data/custom_store'
import membershipSubscriptionService from '@/http/requests/membership/subscription'

const store = new CustomStore({
  key: 'id',
  cacheRawData: false,
  load: async loadOptions => {
    const options = {}
    const filters = (loadOptions.searchValue) ? loadOptions.searchValue : null
    const response = await membershipSubscriptionService.getListByFilter(filters.membershipPlanId)
    const data = response.data.body
    const totalCount = data.length
    return {
      data: data,
      totalCount: totalCount,
    }
  },
})

const memberShipDataSource = new DataSource({
  store,
})

export {
  memberShipDataSource as MembershipDataSource,
}
